@media all and (min-width: 480px) {
  .Settings {
    padding: 60px 0;
  }

  .Settings .LoaderButton {
    display: block;
    margin: 25px auto;
    max-width: 320px;
  }
}

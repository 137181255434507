.Note form {
  padding-bottom: 15px;
}

.ReadingView {
  min-height: 94px;
  color: rgb(73, 80, 87);
  border-color: rgb(206, 212, 218);
  border-style: solid;
  border-width: 1px;
  padding: 9.6px;
  overflow: hidden;
}

.activeParagraph {
  color: yellow;
  background-color: brown;
}

.activeParagraph .activeSentence {
  color: brown;
  background-color: yellow;
}

div#playPause {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  border: 3px solid #092c5c;
  box-sizing: border-box;
  color: white;
  background: radial-gradient(#092c5c, #8fbce6);
  font-size: 125%;
  font-weight: bold;
  padding: 15px 0px;
  height: 60px;
  text-align: center;
}

button {
  display: block;
  width: 100%;
}

.ck.ck-editor__editable td .image-inline img,
.ck.ck-editor__editable th .image-inline img {
  max-width: 900px;
}
